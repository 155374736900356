import Header from './components/header';
import Services from './components/services';
import References from './components/references';
import Contact from './components/contact';
import Footer from './components/footer';
import './App.scss';

function App() {

  return (
    <div className="App">
      <Header />
      <Services />
      <References />
      <Contact/>
      <Footer />
    </div>
  );
}

export default App;
