import React from 'react';
import "./languageSelector.scss"
import { useTranslation } from 'react-i18next';


export default function LanguageSelector() {
    const { i18n, t } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        document.title = t('documentTitle');
    };

    return (
        <div id="languageSelector" >
            <button type="button" onClick={() => changeLanguage('en')}
                disabled={i18n.language === 'en'}>
                EN
            </button>
            <button type="button" onClick={() => changeLanguage('fi')}
                disabled={i18n.language === 'fi'}>
                FI
            </button>
        </div>
    )
}