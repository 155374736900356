import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  fi: {
    translations: require('./i18n/locales/fi/translations.json')
  },
  en: {
    translations: require('./i18n/locales/en/translations.json')
  }
};

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: ['fi','en'],
    debug:true,
    lng: 'en',
    resources: resources,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: false
  });

export default i18n;
