import React from 'react';
import "./footer.scss"
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    return (
        <div id="footer">
            <div className="wrapper">
                <div id="address">
                    <p style={{ fontWeight: "bold" }}>{t('office')}</p>
                    <p>PwnTek Oy</p>
                    <p>Lauttasaarentie 39 A 3</p>
                    <p>00200 Helsinki</p>
                    <p>{t('finland')}</p>
                </div>
            </div>
            <div id="copyright">
                <p >{t('copyright')} &copy; {new Date().getFullYear()} PwnTek Oy</p>
            </div>
        </div>
    );
}

export default Footer;