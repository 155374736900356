import React from "react";
import "./services.scss";
import customSoftwareDevelopment from "../images/custom-software-development.png";
import cloudApplicationsAndArchitecture from "../images/cloud-applications-and-architecture.png";
import devOpsProcessesAndPipelines from "../images/devops-processes-and-pipelines.png";
import compreHensiveStrategyAndConcepts from "../images/comprehensive-strategy-and-concepts.png";
import SectionHeader from "./sectionHeader";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <SectionHeader title={t("servicesHeader")} />
      <div id="services">
        <div className="service">
          <img src={customSoftwareDevelopment} alt={t("softwareHeader")}/>
          <h1>{t("softwareHeader")}</h1>
          <p>{t("softwareContent")}</p>
        </div>
        <div className="service">
          <img src={cloudApplicationsAndArchitecture} alt={t("cloudHeader")} />
          <h1>{t("cloudHeader")}</h1>
          <p>{t("cloudContent")}</p>
        </div>
        <div className="service">
          <img src={devOpsProcessesAndPipelines} alt={t("devOpsHeader")} />
          <h1>{t("devOpsHeader")}</h1>
          <p>{t("devOpsContent")}</p>
        </div>
        <div className="service">
          <img
            src={compreHensiveStrategyAndConcepts}
            alt={t("strategyHeader")}
          />
          <h1>{t("strategyHeader")}</h1>
          <p>{t("strategyContent")}</p>
        </div>
      </div>
      <div
        style={{
          margin: "auto",
          textAlign: "center",
          marginTop: "124px",
        }}
      >

      </div>
    </div>
  );
}

export default Services;
