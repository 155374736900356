import React from 'react';
import "./references.scss"
import siili from '../images/Siili_250.png'
import nordcloud from '../images/Nordcloud_250.png'
import lahitapiola from '../images/Lahitapiola_250.png'
import SectionHeader from './sectionHeader'
import { useTranslation } from 'react-i18next';


function References() {
    const {t } = useTranslation();
    return (
        <div id="references">
            <div className="wrapper">
                <SectionHeader title={t('referencesHeader')} />
                <div id="referencePictures">
                    <img className="referenceLogo" src={lahitapiola} alt="LähiTapiola" id="lahitapiola" />
                    <img className="referenceLogo" src={siili} alt="Siili" id="siili" />
                    <img className="referenceLogo" src={nordcloud} alt="Nordcloud" id="nordcloud" />
                </div>
            </div>
        </div>
    );
}

export default References;