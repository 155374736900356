import React from 'react';
import "./header.scss"
import background from '../images/header-background.png'
import LanguageSelector from './languageSelector'
import { ReactComponent as Logo } from '../images/pwntek-logo.svg';
import { useTranslation } from 'react-i18next';

const headerStyle = {
  backgroundImage: `url(${background})`,
  width:"100%"
}

function Header() {
  const { t } = useTranslation();
  return (
    <div id="header" style={headerStyle}>
      <LanguageSelector/>
      <div className="logo">
        <Logo/>
      </div>
      
      <div className="introWrapper">
        <h1>{t('introText')}</h1>
        <p>{t('introLong')}</p>
      </div>
    </div>
  );
}

export default Header;