import React from 'react';
import "./contact.scss"
import { useTranslation } from 'react-i18next';

function Contact() {
    const { t } = useTranslation();
    return (
        <div id="contactWrapper" className="wrapper">
            <h5 style={{ color: "#93C1D8", textAlign: "center" }}>{t("newChallenge")}</h5>
            <h1 style={{ color: "#41CEFD", textAlign: "center", gridRow: "1/1", fontSize: "1.5rem" }}><a href="mailto:contact@pwntek.com" id="mail">contact@pwntek.com</a></h1>
        </div>
    );
}

export default Contact;