import React from 'react';
import "./sectionHeader.scss"

function SectionHeader(props)  {
    return (
        <div className="sectionGrid">
         <h1 style={{ color: "#41CEFD",textAlign:"center",gridRow:"1/1"}}>{props.title}</h1>
         </div>
    );
}

export default SectionHeader;